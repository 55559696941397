export interface ContactType {
  value: string;
  text: string;
}

export const allTypes = (): ContactType[] => [
  ...contactTypes(),
  ...departmentTypes()
].sort((first, second) => first.text.localeCompare(second.text));

export const receiverTypes = (): ContactType[] => [
  { value: 'all', text: 'Für Alle' },
  { value: 'mental_health_court', text: 'Betreuungsgericht' },
  { value: 'bank', text: 'Bank' },
  { value: 'health_insurance', text: 'Krankenkasse' },
  ...allTypes()
].sort((first, second) => first.text.localeCompare(second.text));

export const departmentTypes = (): ContactType[] => [
  { value: 'authority', text: 'Behörde' },
  { value: 'pension_insurance', text: 'Rentenversicherung' },
  { value: 'service_provider', text: 'Arbeitsamt' },
  { value: 'job_center', text: 'Jobcenter' },
  { value: 'courthouse', text: 'Landratsamt' },
  { value: 'court', text: 'Amtsgericht' },
  { value: 'finance_authority', text: 'Finanzamt' },
  { value: 'welfare_agency', text: 'Sozialamt' },
  { value: 'disability_low', text: 'Behindertenrecht' },
  { value: 'accommodation_allowance_place', text: 'Wohngeldstelle' },
  { value: 'communal_social_union', text: 'Kommunaler Sozial Verband' },
  { value: 'youth_welfare_office', text: 'Jugendamt' },
  { value: 'family_box', text: 'Familienkasse' }
].sort((first, second) => first.text.localeCompare(second.text));

export const contactTypes = (): ContactType[] => [
  { value: 'doctor', text: 'Arzt' },
  { value: 'workstation', text: 'Arbeitsstelle' },
  { value: 'assisted_living', text: 'Betreutes Wohnen' },
  { value: 'dwelling', text: 'Wohnstätte' },
  { value: 'nursing_service', text: 'Pflegedienst' },
  { value: 'services', text: 'Sonstige Dienstleister' },
  { value: 'other', text: 'Sonstiges (z.B. Familienangehörige)' },
  { value: 'foster_home', text: 'Pflegeheim' }
].sort((first, second) => first.text.localeCompare(second.text));

export const departmentKeys = (): string[] => departmentTypes().map((department) => department.value);

