















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CONTACT_LIST, GET_ADDRESS_LIST } from '../store';
import { departmentValues } from '../helper';
import { Contact } from '@/modules/contact/types';
import { ApiResponse } from '@/components/types';

const Contact = namespace('contact');

@Component({
  inheritAttrs: false
})
export default class AddressSelect extends Vue {
  @Prop({ type: Boolean, default: true }) withDepartments!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;

  @Contact.Getter(GET_ADDRESS_LIST) items!: Contact[];
  @Contact.Action(FETCH_CONTACT_LIST) fetch!: () => Promise<ApiResponse>;

  get list() {
    return this.items
      .filter((contact) => {
        if (this.withDepartments) return true;

        return contact.types.filter((type) => -1 === departmentValues().indexOf(type)).length > 0;
      })
      .sort((first, second) => first.name.localeCompare(second.name));
  }

  created() {
    return this.fetch();
  }
}
