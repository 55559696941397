


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactPerson } from '@/modules/contact/types';

@Component({
  inheritAttrs: false
})
export default class ContactPersonSelect extends Vue {
  @Prop({ type: [Array, String] }) value?: string | string[];
  @Prop({ type: Array, default: () => [] }) items!: ContactPerson[];
  @Prop({ type: Boolean, default: false }) multiple!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;

  created() {
    this.$emit('input', this.value);
  }
}
