













import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContactType, receiverTypes } from '@/statics/contactTypes';

@Component({ inheritAttrs: false })
export default class ReceiverTypeSelect extends Vue {
  @Prop({ type: [String, Array] }) value?: string | string[];
  @Prop({ type: Array }) items?: ContactType[];
  @Prop({ type: Boolean, default: true }) required!: boolean;

  get list(): ContactType[] {
    if (this.items) {
      return this.items;
    }

    return [...receiverTypes(), { value: 'conservatorship', text: 'Betreuter' }]
      .sort((first, second) => first.text.localeCompare(second.text));
  }
}
